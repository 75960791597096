import { TagType } from '@amzn/meridian/tag/tag';

export const PURCHASE_ORDER_PAGE_STRINGS = {
  TITLE: 'gat_purchase_orders_page_title',
  GENERIC_ERROR_MESSAGE: 'gat_purchase_orders_page_site_fetch_error_message',
  NO_SITE_ACCESS: 'gat_purchase_orders_page_no_site_access_message',
  SITE_INFO: 'gat_purchase_orders_page_site_info_text',
  REQUIRED: '*',
  SITE: 'gat_purchase_orders_page_site_text',
  NEED_BY_DATE: 'gat_purchase_orders_page_need_by_date_text',
  PO_NUMBER: 'gat_purchase_orders_page_po_number_text',
  SUPPLIER: 'gat_purchase_orders_page_po_supplier_text',
  PO_STATUS: 'gat_purchase_orders_page_po_status_text',
  TOTAL_LINE_ITEMS: 'gat_purchase_orders_page_total_line_items_text',
  PO_AUDIT_COUNT: 'gat_purchase_orders_page_audit_count_text',
  REQUIRED_FIELDS: 'gat_purchase_orders_page_required_fields_text',
  SEARCH: 'gat_purchase_orders_page_search_text',
  CLEAR_FILTERS: 'gat_purchase_orders_page_clear_filters_text',
  ACTIONS: 'gat_purchase_orders_page_actions_text',
  CREATE_AUDIT: 'gat_purchase_orders_page_create_audit_text',
  CONFIRMATION_POPUP_TITLE: 'gat_purchase_orders_page_confirmation_popup_title',
  CONFIRMATION_POPUP_MESSAGE:
    'gat_purchase_orders_page_confirmation_popup_message',
  CONFIRMATION_POPUP_CONFIRM: 'gat_purchase_orders_page_confirmation_confirm',
  CONFIRMATION_POPUP_CANCEL: 'gat_purchase_orders_page_confirmation_cancel',
  PO_DETAILS_POPUP_TITLE: 'gat_purchase_orders_page_po_details_popup_title',
  NO_ORDERS_FOR_SELECTED_SITE: 'gat_purchase_orders_page_no_orders_available',
  CREATE_AUDIT_CONFIRMATION_TITLE:
    'gat_purchase_orders_page_create_audit_confirmation_title',
  CREATE_AUDIT_CONFIRMATION_MESSAGE:
    'gat_purchase_orders_page_create_audit_confirmation_message',
  CANCEL: 'gat_purchase_orders_page_cancel_text',
  CLOSE: 'gat_purchase_orders_page_close_text',
  EXECUTE_AUDIT: 'gat_purchase_orders_page_execute_audit_text',
  SELECT_FILTERS: 'gat_purchase_orders_page_select_filters_text',
  API_FAILURE: 'gat_purchase_orders_page_api_failure_text',
  START_DATE: 'gat_purchase_orders_page_edd_start_date_text',
  END_DATE: 'gat_purchase_orders_page_edd_end_date_text',
  NEED_BY_DATE_RANGE: 'gat_purchase_orders_page_need_by_date_range_text',
  FILTER_BY: 'gat_purchase_orders_page_filter_by_text',
  FILTER_BY_NBD_STATUS: 'gat_purchase_orders_page_filter_by_nbd_status_text',
  FILTER_BY_PO_NUMBER: 'gat_purchase_orders_page_filter_by_po_number_text',
  CREATE_AUDIT_IS_PENDING:
    'gat_purchase_orders_page_create_audit_is_pending_text',
  CREATE_AUDIT_FAILURE_MESSAGE:
    'gat_purchase_orders_page_create_audit_failure_message_text',
  CREATE_AUDIT_BUTTON_TOOLTIP_TEXT:
    'gat_purchase_orders_page_create_audit_button_tooltip_text',
  DEFAULT_FILTER_MESSAGE: 'gat_purchase_orders_page_default_filter_message',
};

export const PURCHASE_ORDER_PAGE_CONSTANTS = {
  FILTER_WIDTH_MOBILE: 140,
  FILTER_WIDTH: 200,
  CARD_VIEW_MAX_WIDTH: '900px',
  PO_STATUS_ISSUED: 'issued',
  PO_TEMPLATE_TYPE: 'Purchase Order',
  FILTER_BY_NBD_STATUS: 'NBD and Status',
  FILTER_BY_PO_NUMBER: 'PO Number',
  NBD_STATUS_STORAGE_KEY: 'purchaseOrderNBDStatus',
  NBD_STATUS_EXPIRY_KEY: 'purchaseOrderNBDStatusExpiry',
  STORAGE_TTL_HOURS: 12,
};

export enum POStatusEnum {
  issued = 'gat_purchase_orders_page_issued_text',
  closed = 'gat_purchase_orders_page_closed_text',
  soft_closed = 'gat_purchase_orders_page_soft_closed_text',
  supplier_window_hold = 'gat_purchase_orders_page_supplier_window_hold_text',
  cancelled = 'gat_purchase_orders_page_cancelled_text',
  buyer_hold = 'gat_purchase_orders_page_buyer_hold_text',
}

export const POTagTypes: Record<keyof typeof POStatusEnum, TagType> = {
  issued: 'theme',
  closed: 'neutral',
  soft_closed: 'neutral',
  supplier_window_hold: 'warning',
  cancelled: 'error',
  buyer_hold: 'warning',
};
