import {
  SiteAudit,
  SiteSupplier,
} from 'src/features/siteInfo/api/get-site-audits';

export enum AuditGeneratedOn {
  'DAILY' = 'gat_site_info_page_daily',
  'WEEKLY' = 'gat_site_info_page_weekly',
  'MONTHLY' = 'gat_site_info_page_monthly',
  'ADHOC' = 'gat_site_info_page_adhoc',
}

export enum SortColumns {
  'gat_site_info_page_audit' = 'auditType',
  'gat_site_info_page_frequency' = 'auditCadence',
  'gat_site_info_page_role' = 'roleName',
}

export enum SortColumnsSiteAudits {
  'gat_site_info_page_audit' = 'auditType',
  'gat_site_info_page_frequency' = 'auditCadence',
  'gat_site_info_page_role' = 'roleName',
}

export enum SortColumnsSupplierAudits {
  'gat_site_info_page_audit' = 'auditType',
}

export const SITE_INFO_PAGE_STRINGS = {
  SITE_INFO: 'gat_site_info_page_title',
  GENERIC_ERROR_MESSAGE: 'gat_site_info_page_site_fetch_error_message',
  NO_SITE_ACCESS: 'gat_site_info_page_no_site_access',
  SITE: 'gat_site_info_page_site',
  AUDIT: 'gat_site_info_page_audit',
  FREQUENCY: 'gat_site_info_page_frequency',
  SUPPLIER_NAME: 'gat_site_info_page_supplier_name',
  SUPPLIER_COUPA_ID: 'gat_site_info_page_supplier_coupa_id',
  AUDIT_GENERATED_ON: 'gat_site_info_page_audit_generated_on',
  ROLE: 'gat_site_info_page_role',
  NO_SITE_INFO: 'gat_site_info_page_no_site_info',
  SORT_BY: 'gat_site_info_page_sort_by',
  MISSING_SUPPLIER_MESSAGE: 'gat_site_info_page_missing_supplier_message',
  INFO_MESSAGE: 'gat_site_info_page_info_message',
  SITE_TIMEZONE: 'gat_site_info_page_timezone',
  SITE_AUDITS: 'gat_site_info_page_site_audit',
  SITE_SUPPLIERS: 'gat_site_info_page_site_supplier',
  SITE_COUNTRY: 'gat_profile_page_country',
  SITE_ADMINS: 'gat_site_info_page_site_admins',
  ADD_SITE_LABEL: 'gat_site_info_page_add_site_button_label',
  SITE_ID_FORM_LABEL: 'gat_site_info_page_site_id_form_label',
  SITE_ID_FORM_PLACEHOLDER: 'gat_site_info_page_site_id_form_placeholder',
  SITE_ID_FORM_ERROR_MESSAGE: 'gat_site_info_page_site_id_form_error_message',
  COUNTRY_FORM_PLACEHOLDER: 'gat_site_info_page_country_form_placeholder',
  COUNTRY_FORM_ERROR_MESSAGE: 'gat_site_info_page_country_form_error_message',
  TIMEZONE_FORM_PLACEHOLDER: 'gat_site_info_page_timezone_form_placeholder',
  TIMEZONE_FORM_ERROR_MESSAGE: 'gat_site_info_page_timezone_form_error_message',
  CANCEL_BUTTON_LABEL: 'gat_audit_conduct_page_cancel_button_text',
  CONFIRM_BUTTON_LABEL: 'gat_audit_conduct_page_confirm_button_text',
  EMPTY_SITE_AUDIT_MESSAGE: 'gat_site_info_page_no_site_audits',
  EMPTY_SITE_SUPPLIER_MESSAGE: 'gat_site_info_page_no_site_suppliers',
  ADD_SITE_AUDIT_LABEL: 'gat_site_info_page_add_site_audit_label',
  ADD_SITE_SUPPLIER_LABEL: 'gat_site_info_page_add_site_supplier_label',
  ADD_TABLE_ITEM_LABEL: 'gat_site_info_page_add_table_item_button_label',
  CADENCE_FORM_LABEL: 'gat_site_info_page_cadence_form_label',
  CADENCE_FORM_PLACEHOLDER: 'gat_site_info_page_cadence_form_placeholder',
  AUDIT_TYPE_FORM_LABEL: 'gat_advanced_search_page_audit_type_text',
  AUDIT_TYPE_FORM_PLACEHOLDER: 'gat_site_info_page_audit_type_form_placeholder',
  ROLE_FORM_PLACEHOLDER: 'gat_site_info_page_role_form_placeholder',
  EMPTY_VALUE_ERROR_MESSAGE:
    'gat_site_info_page_form_empty_value_error_message',
  ADD_SITE_AUDIT_SUCCESS_MESSAGE:
    'gat_site_info_page_add_site_audit_success_message',
  AUDIT_CADENCE_EXISTS_MESSAGE:
    'gat_site_info_page_audit_cadence_exists_message',
  ADD_SITE_SUPPLIER_SUCCESS_MESSAGE:
    'gat_site_info_page_add_site_supplier_success_message',
  ADD_SITE_AUDIT_ERROR_MESSAGE:
    'gat_site_info_page_add_site_audit_error_message',
  ADD_SITE_SUPPLIER_ERROR_MESSAGE:
    'gat_site_info_page_add_site_supplier_error_message',
  SUPPLIER_FORM_LABEL: 'gat_purchase_orders_page_po_supplier_text',
  SUPPLIER_FORM_PLACEHOLDER: 'gat_site_info_page_supplier_form_placeholder',
  SUPPLIER_FORM_ERROR_MESSAGE: 'gat_site_info_page_supplier_form_error_message',
  SITE_INFO_PAGE_TITLE: 'gat_site_info_page_title',
  ADD_NEW_SITE_SUCCESS_MESSAGE:
    'gat_site_info_page_add_new_site_success_message',
  ADD_NEW_SITE_ERROR_MESSAGE: 'gat_site_info_page_add_new_site_error_message',
  DELETE_ITEM_LABEL: 'gat_site_info_page_delete_item_label',
  DELETE_ITEM_MESSAGE: 'gat_site_info_page_delete_item_message',
  DELETE_ITEM_CONFIRM_MESSAGE: 'gat_site_info_page_delete_item_confirm_message',
  DELETE_SITE_AUDIT_SUCCESS_MESSAGE:
    'gat_site_info_page_delete_site_audit_success_message',
  DELETE_SITE_AUDIT_ERROR_MESSAGE:
    'gat_site_info_page_delete_site_audit_error_message',
  DELETE_SITE_SUPPLIER_SUCCESS_MESSAGE:
    'gat_site_info_page_delete_site_supplier_success_message',
  DELETE_SITE_SUPPLIER_ERROR_MESSAGE:
    'gat_site_info_page_delete_site_supplier_error_message',
};

export const CARD_VIEW_MAX_WIDTH = '900px';

export type TableHeaderProps = {
  label: string;
  sortColumn?: string;
};

export const SiteAuditTableHeaderLabels: TableHeaderProps[] = [
  {
    label: SITE_INFO_PAGE_STRINGS.AUDIT,
    sortColumn: 'auditType',
  },
  {
    label: SITE_INFO_PAGE_STRINGS.ROLE,
    sortColumn: 'roleName',
  },
  {
    label: SITE_INFO_PAGE_STRINGS.FREQUENCY,
    sortColumn: 'auditCadence',
  },
  {
    label: SITE_INFO_PAGE_STRINGS.AUDIT_GENERATED_ON,
  },
];

export const SiteSupplierTableHeaderLabels: TableHeaderProps[] = [
  {
    label: SITE_INFO_PAGE_STRINGS.AUDIT,
    sortColumn: 'auditType',
  },
  {
    label: SITE_INFO_PAGE_STRINGS.SUPPLIER_NAME,
  },
  {
    label: SITE_INFO_PAGE_STRINGS.SUPPLIER_COUPA_ID,
  },
];

export const SiteInfoTabs = [
  {
    tabId: 'site-audits',
    tabLabel: SITE_INFO_PAGE_STRINGS.SITE_AUDITS,
  },
  {
    tabId: 'site-suppliers',
    tabLabel: SITE_INFO_PAGE_STRINGS.SITE_SUPPLIERS,
  },
];

export const isSiteAudits = (currentTab: string) => {
  return currentTab === 'site-audits';
};

export const isDataEmtpy = (
  dataList: SiteAudit[] | SiteSupplier[] | undefined,
) => {
  return dataList && dataList.length === 0;
};

export const CadenceList = [
  {
    label: 'Daily',
    value: 'DAILY',
  },
  {
    label: 'Weekly',
    value: 'WEEKLY',
  },

  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
  {
    label: 'Ad hoc',
    value: 'ADHOC',
  },
];
