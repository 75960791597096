import React, { useEffect, useState } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import { useNavigate } from 'react-router-dom';
import {
  COGNITO_ATTRIBUTES,
  PROFILE_PAGE_STRINGS,
} from 'src/features/profile/constants';
import { PAGE_PATHS } from 'src/constants';
import { useProfileInfoStore } from 'src/store/profile-info';
import { useAuthTokenPayload } from 'src/hooks';

const withProfileCheck = (Component: any) => {
  return (props: any) => {
    const [showModal, setShowModal] = useState<boolean | null>(null);
    const { authTokenPayload } = useAuthTokenPayload();
    const { bundle } = useProfileInfoStore();
    const navigate = useNavigate();
    useEffect(() => {
      // Check if country and language are present in the auth header
      if (!authTokenPayload.loading && authTokenPayload.data) {
        setShowModal(
          !authTokenPayload.data[COGNITO_ATTRIBUTES.COUNTRY_ATTR] ||
            !authTokenPayload.data[COGNITO_ATTRIBUTES.LANGUAGE_ATTR],
        );
      }
    }, [authTokenPayload]);
    const handleRedirectToProfile = () => {
      setShowModal(false);
      navigate(PAGE_PATHS.EDIT_PROFILE);
    };

    if (showModal === null) {
      return <></>;
    }

    return showModal ? (
      <Modal
        title={bundle.formatMessage(PROFILE_PAGE_STRINGS.POP_UP_TITLE)}
        open={showModal}
      >
        <Text id="modal-description">
          {bundle.formatMessage(PROFILE_PAGE_STRINGS.MISSING_FIELDS_MSG)}
        </Text>
        <ModalFooter>
          <Row alignmentHorizontal="center" widths="fit">
            <Button
              type="tertiary"
              size="small"
              onClick={handleRedirectToProfile}
            >
              {bundle.formatMessage(PROFILE_PAGE_STRINGS.REDIRECT)}
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    ) : (
      <Component {...props} />
    );
  };
};

export default withProfileCheck;
