import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import AppLayout from '@amzn/meridian/app-layout';
import { AppFooter, AppMastHead, AppSideMenu, Loader } from 'src/components';
import Theme from '@amzn/meridian/theme';
import { PAGE_PATHS } from 'src/constants';
import ToastListener from 'src/components/ToastListener';
import ApproveAccess from 'src/features/access/routes/ApproveAccess';
import withProfileCheck from 'src/features/utils/profileRedirectPopup';
import { AppAuthorization } from 'src/components/AppAuthorization';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import ResetScroll from 'src/components/RestScroll';
import useThemeMode from 'src/features/profile/hooks/use-theme-mode';
const Home = lazy(() => import('src/features/home/routes/Home'));
const Profile = lazy(() => import('src/features/profile/routes/Profile'));
const EditProfile = lazy(
  () => import('src/features/profile/routes/EditProfile'),
);
const AuditQuestions = lazy(
  () => import('src/features/home/routes/AuditQuestions'),
);
const RequestAccess = lazy(
  () => import('src/features/access/routes/RequestAccess'),
);
const ViewAccess = lazy(() => import('src/features/access/routes/ViewAccess'));
const UserAccess = lazy(() => import('src/features/access/routes/UserAccess'));
const GrantAccess = lazy(
  () => import('src/features/access/routes/GrantAccess'),
);
const SearchPage = lazy(() => import('src/features/search/pages/SearchPage'));
const SiteInfo = lazy(() => import('src/features/siteInfo/pages/SiteInfoPage'));
const VendorManagement = lazy(
  () => import('src/features/supplierInfo/routes/VendorManagement'),
);
const VendorInfoPage = lazy(
  () => import('src/features/supplierInfo/routes/VendorInfo'),
);
const PurchaseOrders = lazy(
  () => import('src/features/purchaseOrder/routes/PurchaseOrdersRoute'),
);

const App = () => {
  const { themeMode } = useThemeMode();
  return (
    <AppAuthorization>
      <Theme palette="blue" mode={themeMode}>
        <AppLayout
          headerComponent={AppMastHead}
          sidebarComponent={AppSideMenu}
          footerComponent={AppFooter}
          alwaysShowScrollbar={true}
          backgroundColor={'principal'}
        >
          <AppMastHead />
          <AppSideMenu />
          <AppFooter />
          <ResetScroll />
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
          <ToastListener />
        </AppLayout>
      </Theme>
    </AppAuthorization>
  );
};

const routes = [
  { path: PAGE_PATHS.HOME, element: Home },
  { path: PAGE_PATHS.PROFILE, element: Profile },
  { path: PAGE_PATHS.EDIT_PROFILE, element: EditProfile },
  { path: PAGE_PATHS.REQUEST_ACCESS, element: RequestAccess },
  { path: PAGE_PATHS.VIEW_ACCESS, element: ViewAccess },
  { path: PAGE_PATHS.APPROVE_ACCESS_REQUESTS, element: ApproveAccess },
  { path: PAGE_PATHS.AUDIT_QUESTION_PAGE, element: AuditQuestions },
  { path: PAGE_PATHS.USER_ACCESS, element: UserAccess },
  { path: PAGE_PATHS.ADVANCED_SEARCH, element: SearchPage },
  { path: PAGE_PATHS.GRANT_ACCESS, element: GrantAccess },
  { path: PAGE_PATHS.VENDOR_MANAGEMENT, element: VendorManagement },
  { path: PAGE_PATHS.SUPPLIER_INFO_PAGE, element: VendorInfoPage },
  { path: PAGE_PATHS.SITE_INFO, element: SiteInfo },
  { path: PAGE_PATHS.PURCHASE_ORDERS, element: PurchaseOrders },
  { path: '*', element: Home },
];

const wrappedRoutes = routes.map(({ path, element }) => {
  const WrappedLazyComponent = path.includes('profile')
    ? element
    : withProfileCheck(element);

  return {
    path,
    element: <WrappedLazyComponent />,
  };
});

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: wrappedRoutes,
  },
];
