import { useQuery } from '@tanstack/react-query';
import { ExtractFnReturnType } from 'src/lib/react-query';
import { OrchestratorApiService } from 'src/service';
import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { GetUserRolesResponse } from 'src/features/home/types';
import { checkIfInternalUser } from 'src/features/access/utils/internal-user-util';

const getApprovalRoleList = async (
  filterSite: string,
  status: string,
): Promise<GetUserRolesResponse> => {
  const response = await OrchestratorApiService.get(
    BACKEND_PATHS.GET_ROLE_REQUESTS(filterSite, status),
  );
  const data: GetUserRolesResponse = response.data;
  data.userRoles = data.userRoles.filter(checkIfInternalUser);
  return data;
};

type QueryFnType = typeof getApprovalRoleList;

export const useApprovalRequests = (filterSite: string, status: string) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['getApprovalRoleRequests', filterSite, status],
    queryFn: () => getApprovalRoleList(filterSite, status),
  });
};
