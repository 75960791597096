export const PROFILE_PAGE_STRINGS = {
  FIRST_NAME: 'gat_profile_page_first_name_text',
  LAST_NAME: 'gat_profile_page_last_name_text',
  EMAIL: 'gat_profile_page_email_text',
  COUNTRY: 'gat_profile_page_country',
  COUNTRY_SELECT_PLACEHOLDER: 'gat_profile_page_select_country_text',
  LANGUAGE_SELECT_PLACEHOLDER: 'gat_profile_page_select_language_text',
  PREFERRED_LANGUAGE: 'gat_profile_page_preferred_language_text',
  PAGE_TITLE: 'gat_profile_page_profile_text',
  EDIT_PAGE_TITLE: 'gat_profile_page_edit_profile_text',
  POP_UP_TITLE: 'gat_profile_page_pop_up_title',
  REQUIRED: 'gat_required_field_text',
  MISSING_FIELDS_MSG: 'gat_profile_page_missing_info_msg',
  COUNTRY_NOT_SELECTED_MSG: 'gat_profile_page_country_not_selected_message',
  LANGUAGE_NOT_SELECTED_MSG: 'gat_profile_page_language_not_selected_message',
  REDIRECT: 'gat_profile_page_redirect_message',
  UPDATE_SUCCESS: 'gat_profile_page_update_success_message',
  UPDATE_FAILURE: 'gat_profile_page_update_failure_message',
  EDIT: 'gat_profile_page_edit_button_text',
  SAVE: 'gat_profile_page_save_button_text',
  CANCEL: 'gat_audit_info_page_audit_cancel_button_text',
  COUNTRY_FETCH_ERROR: 'gat_profile_page_country_fetch_error_text',
  SYSTEM_THEME: 'gat_profile_page_site_mode',
  LIGHT_THEME: 'gat_profile_page_light_mode',
  DARK_THEME: 'gat_profile_page_dark_mode',
};
