import { lazy } from 'react';
import { PAGE_PATHS } from 'src/constants';
import AppLayout from '@amzn/meridian/app-layout';
import { AppFooter, AppMastHead } from 'src/components';
import { Outlet } from 'react-router-dom';
import Theme from '@amzn/meridian/theme';
import {
  Theme as AmplifyTheme,
  ThemeProvider,
  useTheme,
} from '@aws-amplify/ui-react';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import ToastListener from 'src/components/ToastListener';

const Welcome = lazy(() => import('src/features/auth/routes/Welcome'));
const Login = lazy(() => import('src/features/auth/routes/Login'));

const App = () => {
  const { tokens } = useTheme();

  const theme: AmplifyTheme = {
    name: 'GAT Amplify Theme',
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${tokens.colors.overlay['10']}`,
            borderWidth: '0',
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: tokens.colors.neutral['100'],
          },
          link: {
            color: '#004F6C',
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px #00A8E1`,
          },
        },
        tabs: {
          item: {
            color: tokens.colors.neutral['80'],
            _active: {
              borderColor: tokens.colors.neutral['100'],
              color: '#000',
            },
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Theme palette="blue" mode="light">
        <AppLayout
          headerComponent={AppMastHead}
          footerComponent={AppFooter}
          backgroundColor={'principal'}
        >
          <AppMastHead />
          <AppFooter />
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
          <ToastListener />
        </AppLayout>
      </Theme>
    </ThemeProvider>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: PAGE_PATHS.WELCOME,
        element: <Welcome />,
      },
      {
        path: PAGE_PATHS.LOGIN,
        element: <Login />,
      },
      //Making the Welcome page the 404 page.
      {
        path: '*',
        element: <Welcome />,
      },
    ],
  },
];
