import Card from '@amzn/meridian/card';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import {
  APPROVE_ACCESS_PAGE_STRINGS,
  ApproveAccessSortColumn,
} from '../constants';
import { BundledLocalizationProps } from 'src/interfaces/BundleInterface';
import React, { useState } from 'react';
import { TableSort } from 'src/interfaces/TableSort';
import { UserInfo } from 'src/features/home/types';
import Select, { SelectOption } from '@amzn/meridian/select';
import arrowUpLargeTokens from '@amzn/meridian-tokens/base/icon/arrow-up-large';
import arrowDownLargeTokens from '@amzn/meridian-tokens/base/icon/arrow-down-large';
import arrowsUpAndDownTokens from '@amzn/meridian-tokens/base/icon/arrows-up-and-down';
import Pagination from '@amzn/meridian/pagination';
import ModifyAccessModal from 'src/features/access/components/ModifyAccessModal';
import { sortIconStyle, sortMenuStyle, sortStyle } from 'src/styles';
import { useProfileInfoStore } from 'src/store/profile-info';
import { AccessAction } from 'src/features/access/types/access-action';
import { ApproveAccessActionButtons } from 'src/features/access/components/ApproveAccessActionButtons';
import { sortByColumn } from 'src/features/access/utils/sort-by-column-util';

const itemsPerPage = 5;

const CardView: React.FC<
  {
    approvalInfo: UserInfo;
    refetch: any;
    showSiteColumn: boolean;
  } & BundledLocalizationProps
> = ({ approvalInfo, refetch, bundle, showSiteColumn }) => {
  const { role, recordId, siteId, siteCountryCode, status, userId } =
    approvalInfo;
  const [isModifyAccessModalOpen, setIsModifyAccessModalOpen] = useState(false);
  const [actionType, setActionType] = useState<AccessAction>('APPROVED');

  return (
    <>
      <Card spacingInset="none">
        <Column spacingInset={'400'}>
          <Row widths="grid-4" alignmentVertical="top" wrap="down">
            <Column spacing="none" spacingInset={'none'}>
              <Text type="h100">
                {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.ROLE)}
              </Text>
              <Text>{role}</Text>
            </Column>
            <Column spacing="none" spacingInset={'none'}>
              <Text type="h100">
                {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.USER)}
              </Text>
              <Text>{userId}</Text>
            </Column>
          </Row>

          {showSiteColumn && (
            <Row widths="grid-4" alignmentVertical="top" wrap="down">
              <Column spacing="none" spacingInset={'none'}>
                <Text type="h100">
                  {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.SITE)}
                </Text>
                <Text>{siteId}</Text>
              </Column>
              <Column spacing="none" spacingInset={'none'}>
                <Text type="h100">
                  {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.COUNTRY)}
                </Text>
                <Text>{siteCountryCode}</Text>
              </Column>
            </Row>
          )}

          <Row widths="grid-4" alignmentVertical="top" wrap="down">
            <Column spacing="none" spacingInset={'none'}>
              <Text type="h100">
                {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.ACTION)}
              </Text>
              <ApproveAccessActionButtons
                status={status}
                setActionType={setActionType}
                setIsModifyAccessModalOpen={setIsModifyAccessModalOpen}
                bundle={bundle}
              />
            </Column>
          </Row>
          {isModifyAccessModalOpen && (
            <ModifyAccessModal
              isOpen={isModifyAccessModalOpen}
              onClose={() => setIsModifyAccessModalOpen(false)}
              role={role}
              siteId={siteId}
              recordId={recordId}
              consensus={actionType}
              email={userId}
              refetchUserRequestedRoles={refetch}
              bundle={bundle}
            />
          )}
        </Column>
      </Card>
    </>
  );
};

export const ApproveAccessListCardView: React.FC<{
  approvalRequests: UserInfo[];
  refetch: any;
  showSiteColumn: boolean;
}> = ({ approvalRequests, refetch, showSiteColumn }) => {
  const { bundle } = useProfileInfoStore();
  const numberOfPages = Math.ceil(
    (approvalRequests?.length ?? 0) / itemsPerPage,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;

  const [sortState, setSortState] = useState<TableSort<keyof UserInfo>>({
    sortColumn: 'userId',
    sortDirection: 'ascending',
  });

  const changeSortDirection = () => {
    if (sortState.sortDirection == 'ascending') {
      setSortState({ ...sortState, sortDirection: 'descending' });
    } else {
      setSortState({ ...sortState, sortDirection: 'ascending' });
    }
  };
  sortByColumn(approvalRequests, sortState);

  const changeSortColumn = (value: keyof UserInfo) => {
    setSortState({ ...sortState, sortColumn: value });
  };

  return (
    <>
      <div className={sortMenuStyle}>
        <Text>{bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.SORT_BY)}</Text>
        <div className={sortStyle}>
          <Select
            width={200}
            value={sortState.sortColumn}
            onChange={changeSortColumn}
          >
            {Object.entries(ApproveAccessSortColumn).map(
              ([key, sortColumn]) => (
                <SelectOption
                  key={sortColumn}
                  label={bundle.formatMessage(key)}
                  value={sortColumn}
                />
              ),
            )}
            {showSiteColumn && (
              <SelectOption
                key="siteId"
                label={bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.SITE)}
                value="siteId"
              />
            )}
            {showSiteColumn && (
              <SelectOption
                key="siteCountryCode"
                label={bundle.formatMessage(
                  APPROVE_ACCESS_PAGE_STRINGS.COUNTRY,
                )}
                value="siteCountryCode"
              />
            )}
          </Select>
          <div onClick={changeSortDirection} className={sortIconStyle}>
            <Icon
              tokens={
                sortState.sortDirection == 'ascending'
                  ? arrowUpLargeTokens
                  : sortState.sortDirection == 'descending'
                  ? arrowDownLargeTokens
                  : arrowsUpAndDownTokens
              }
            />
          </div>
        </div>
      </div>
      <Column>
        {approvalRequests
          ?.slice(firstVisibleIndex, lastVisibleIndex)
          .map((approvalInfo: UserInfo) => (
            <CardView
              key={`${approvalInfo.userId}-${approvalInfo.siteId}-${approvalInfo.role}-${approvalInfo.roleId}`}
              approvalInfo={approvalInfo}
              refetch={refetch}
              bundle={bundle}
              showSiteColumn={showSiteColumn}
            />
          ))}
        <Column alignmentHorizontal="end">
          {approvalRequests?.length !== 0 && (
            <Pagination
              showSkipArrows={true}
              numberOfPages={numberOfPages}
              onChange={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </Column>
      </Column>
    </>
  );
};
