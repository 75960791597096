import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import { APPROVE_ACCESS_PAGE_STRINGS } from 'src/features/access/constants';
import Icon from '@amzn/meridian/icon';
import checkCircle from '@amzn/meridian-tokens/base/icon/check-circle';
import closeCircle from '@amzn/meridian-tokens/base/icon/close-circle';
import React from 'react';
import { AccessAction } from 'src/features/access/types/access-action';
import { BundledLocalizationProps } from 'src/interfaces/BundleInterface';
import { useIsMobile } from 'src/hooks';
import Tooltip from '@amzn/meridian/tooltip';

export const ApproveAccessActionButtons: React.FC<
  {
    status: string;
    setActionType: React.Dispatch<React.SetStateAction<AccessAction>>;
    setIsModifyAccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  } & BundledLocalizationProps
> = ({ status, setIsModifyAccessModalOpen, setActionType, bundle }) => {
  const handleModifyAccessClick = (action: AccessAction) => {
    setActionType(action);
    setIsModifyAccessModalOpen(true);
  };
  const isMobile = useIsMobile();

  return (
    <ButtonGroup>
      {status === 'PENDING' && (
        <>
          {isMobile ? (
            <Tooltip
              position="bottom"
              title={bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.APPROVE)}
            >
              <ButtonOption onClick={() => handleModifyAccessClick('APPROVED')}>
                <Icon tokens={checkCircle} />
              </ButtonOption>
            </Tooltip>
          ) : (
            <ButtonOption onClick={() => handleModifyAccessClick('APPROVED')}>
              {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.APPROVE)}
              <Icon tokens={checkCircle} />
            </ButtonOption>
          )}
          {isMobile ? (
            <Tooltip
              position="bottom"
              title={bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.REJECT)}
            >
              <ButtonOption onClick={() => handleModifyAccessClick('REJECTED')}>
                <Icon tokens={closeCircle} />
              </ButtonOption>
            </Tooltip>
          ) : (
            <ButtonOption onClick={() => handleModifyAccessClick('REJECTED')}>
              {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.REJECT)}
              <Icon tokens={closeCircle} />
            </ButtonOption>
          )}
        </>
      )}
      {status === 'APPROVED' &&
        (isMobile ? (
          <Tooltip
            position="bottom"
            title={bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.REVOKE)}
          >
            <ButtonOption onClick={() => handleModifyAccessClick('REVOKED')}>
              <Icon tokens={closeCircle} />
            </ButtonOption>
          </Tooltip>
        ) : (
          <ButtonOption onClick={() => handleModifyAccessClick('REVOKED')}>
            {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.REVOKE)}
            <Icon tokens={closeCircle} />
          </ButtonOption>
        ))}
    </ButtonGroup>
  );
};
