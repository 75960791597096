import { css } from '@emotion/css';
import { colorGray200 } from '@amzn/meridian-tokens/base/color';
import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import { useProfileInfoStore } from 'src/store/profile-info';
import { GAT_STRINGS } from 'src/features/auth/constants/static';
import Text from '@amzn/meridian/text';
import { useIsInternal } from 'src/hooks/use-is-internal';

const footerStyle = css({
  borderTop: `1px solid ${colorGray200}`,
  position: 'sticky',
  left: 0,
});

export const AppFooter = () => {
  const { bundle } = useProfileInfoStore();
  const { data: isInternal } = useIsInternal();

  return (
    <div className={footerStyle}>
      <Column backgroundColor="alternateSecondary" spacingInset="400 500">
        <Row
          maxWidth="100%"
          alignmentHorizontal={isInternal ? 'center' : 'justify'}
          {...(isInternal ? {} : { widths: ['grid-3', 'grid-9'] })}
        >
          {!isInternal && (
            <Column spacingInset={'200'}>
              <Link
                href="https://supply.amazon.com/privacy-notice"
                target="_blank"
                rel="noreferrer"
                type="secondary"
              >
                <Text>
                  {bundle.formatMessage(GAT_STRINGS.GAT_PRIVACY_NOTICE)}
                </Text>
              </Link>
            </Column>
          )}
          <Column alignmentHorizontal={'end'} spacingInset={'200'}>
            <Text>{bundle.formatMessage(GAT_STRINGS.GAT_FOOTER_TEXT)}</Text>
          </Column>
        </Row>
      </Column>
    </div>
  );
};
