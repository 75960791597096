import React, { useCallback, useEffect, useState } from 'react';
import SideMenu, { SideMenuLink } from '@amzn/meridian/side-menu';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Button from '@amzn/meridian/button';
import { useSideMenuStore } from 'src/store/side-menu-store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsMobile } from 'src/hooks';
import { PAGE_PATHS } from 'src/constants';
import { useProfileInfoStore } from 'src/store/profile-info';
import { GAT_STRINGS } from 'src/features/auth/constants/static';
import { HOME_PAGE_STRINGS } from 'src/features/home/constants';
import {
  APPROVE_ACCESS_PAGE_STRINGS,
  GRANT_ACCESS_PAGE_STRINGS,
  REQUEST_ACCESS_PAGE_STRINGS,
  USER_ACCESS_PAGE_STRINGS,
  VIEW_ACCESS_PAGE_STRINGS,
} from 'src/features/access/constants';
import { PROFILE_PAGE_STRINGS } from 'src/features/profile/constants';
import { ADVANCED_SEARCH_PAGE_STRINGS } from 'src/features/search/constants';
import { useCountry } from 'src/hooks/use-country';
import { useAuthorizationStore } from 'src/store/user-authorization-store';
import Icon from '@amzn/meridian/icon';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import { SITE_INFO_PAGE_STRINGS } from 'src/features/siteInfo/constants';
import {
  VENDOR_DETAILS_PAGE,
  VENDOR_MANAGEMENT_PAGE_STRINGS,
} from 'src/features/supplierInfo/constants';
import { useCoupaId } from 'src/hooks/use-coupaid';
import { PURCHASE_ORDER_PAGE_STRINGS } from 'src/features/purchaseOrder/constants';

export const AppSideMenu = () => {
  const { bundle } = useProfileInfoStore();

  const { signOut } = useAuthenticator();
  const { country, isLoading: isCountryLoading } = useCountry();
  const { coupaSupplierId, isLoading: isCoupaIdLoading } = useCoupaId();
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useIsMobile();

  const { isSideMenuOpen, setIsSideMenuOpen } = useSideMenuStore();
  const [accessButtonIsOpen, setAccessButtonIsOpen] = useState<boolean>(
    location.pathname.startsWith('/access'),
  );

  const { isInternal, isSiteAdmin, isSystemAdmin, isPurchaseOrderAuditor } =
    useAuthorizationStore();
  // On click navigate to the new page
  // On Mobile - close the menu after clicking a link.
  const onClickLink = useCallback(
    (href: string) => {
      navigate(href);
      if (isMobile) setIsSideMenuOpen(false);
    },
    [isMobile, history, setIsSideMenuOpen],
  );

  // Handle case where the browser resizes.
  useEffect(() => {
    setIsSideMenuOpen(!isMobile);
  }, [isMobile, setIsSideMenuOpen]);

  return (
    <SideMenu
      open={isSideMenuOpen}
      type={isMobile ? 'overlay' : 'default'}
      maxWidth={'16rem'}
      onClose={isMobile ? () => setIsSideMenuOpen(false) : undefined}
    >
      <SideMenuLink
        href="/"
        selected={location.pathname === '/'}
        onClick={onClickLink}
      >
        {bundle.formatMessage(HOME_PAGE_STRINGS.HOME)}
      </SideMenuLink>
      {isInternal && (
        <SideMenuLink
          onClick={() => setAccessButtonIsOpen((c) => !c)}
          open={accessButtonIsOpen}
        >
          {bundle.formatMessage(GAT_STRINGS.GAT_ACCESS_NAV_TEXT)}
          {!isSystemAdmin && (
            <SideMenuLink
              href={PAGE_PATHS.REQUEST_ACCESS}
              selected={location.pathname === PAGE_PATHS.REQUEST_ACCESS}
              onClick={onClickLink}
              onEscape={() => setAccessButtonIsOpen(false)}
            >
              {bundle.formatMessage(REQUEST_ACCESS_PAGE_STRINGS.REQUEST_ACCESS)}
            </SideMenuLink>
          )}
          <SideMenuLink
            href={PAGE_PATHS.VIEW_ACCESS}
            selected={location.pathname === PAGE_PATHS.VIEW_ACCESS}
            onClick={onClickLink}
            onEscape={() => setAccessButtonIsOpen(false)}
          >
            {bundle.formatMessage(VIEW_ACCESS_PAGE_STRINGS.VIEW_ACCESS)}
          </SideMenuLink>

          {(isSiteAdmin || isSystemAdmin) && (
            <SideMenuLink
              href={PAGE_PATHS.APPROVE_ACCESS_REQUESTS}
              selected={
                location.pathname === PAGE_PATHS.APPROVE_ACCESS_REQUESTS
              }
              onClick={onClickLink}
              onEscape={() => setAccessButtonIsOpen(false)}
            >
              {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.APPROVE_ACCESS)}
            </SideMenuLink>
          )}
          {isSystemAdmin && (
            <SideMenuLink
              href={PAGE_PATHS.USER_ACCESS}
              selected={location.pathname === PAGE_PATHS.USER_ACCESS}
              onClick={onClickLink}
              onEscape={() => setAccessButtonIsOpen(false)}
            >
              {bundle.formatMessage(USER_ACCESS_PAGE_STRINGS.USER_ACCESS)}
            </SideMenuLink>
          )}
          {(isSiteAdmin || isSystemAdmin) && (
            <SideMenuLink
              href={PAGE_PATHS.GRANT_ACCESS}
              selected={location.pathname === PAGE_PATHS.GRANT_ACCESS}
              onClick={onClickLink}
              onEscape={() => setAccessButtonIsOpen(false)}
            >
              {bundle.formatMessage(GRANT_ACCESS_PAGE_STRINGS.GRANT_ACCESS)}
            </SideMenuLink>
          )}
        </SideMenuLink>
      )}
      <SideMenuLink
        href="/profile"
        selected={location.pathname === '/profile'}
        onClick={onClickLink}
      >
        {bundle.formatMessage(PROFILE_PAGE_STRINGS.PAGE_TITLE)}
      </SideMenuLink>
      <SideMenuLink
        href="/audit-search"
        selected={location.pathname === '/audit-search'}
        onClick={onClickLink}
      >
        {bundle.formatMessage(ADVANCED_SEARCH_PAGE_STRINGS.AUDIT_SEARCH)}
      </SideMenuLink>

      {isInternal && (
        <SideMenuLink
          href="/site-information"
          selected={location.pathname === '/site-information'}
          onClick={onClickLink}
        >
          {bundle.formatMessage(SITE_INFO_PAGE_STRINGS.SITE_INFO)}
        </SideMenuLink>
      )}

      {isInternal ? (
        <SideMenuLink
          href={PAGE_PATHS.VENDOR_MANAGEMENT}
          selected={
            location.pathname === PAGE_PATHS.VENDOR_MANAGEMENT ||
            location.pathname.startsWith('/supplier')
          }
          onClick={onClickLink}
        >
          {bundle.formatMessage(VENDOR_MANAGEMENT_PAGE_STRINGS.TITLE)}
        </SideMenuLink>
      ) : (
        <SideMenuLink
          href={PAGE_PATHS.SUPPLIER_INFO_PAGE_VIEW(coupaSupplierId as string)}
          selected={location.pathname.startsWith('/supplier')}
          onClick={onClickLink}
          disabled={isCountryLoading || isCoupaIdLoading}
        >
          {bundle.formatMessage(VENDOR_DETAILS_PAGE.TITLE)}
        </SideMenuLink>
      )}

      {isInternal &&
        (isSystemAdmin || isSiteAdmin || isPurchaseOrderAuditor) && (
          <SideMenuLink
            href={PAGE_PATHS.PURCHASE_ORDERS}
            selected={
              location.pathname === PAGE_PATHS.PURCHASE_ORDERS ||
              location.pathname.startsWith('/purchase')
            }
            onClick={onClickLink}
          >
            {bundle.formatMessage(PURCHASE_ORDER_PAGE_STRINGS.TITLE)}
          </SideMenuLink>
        )}

      {isInternal && (
        <SideMenuLink
          href={`https://w.amazon.com/bin/view/ISCORE/Products/GAT/`}
          target="_blank"
        >
          {bundle.formatMessage(GAT_STRINGS.GAT_HELP_WIKI)}{' '}
          <Icon tokens={exportSmallTokens} />
        </SideMenuLink>
      )}

      {!isInternal && (
        <SideMenuLink
          href={`https://m.media-amazon.com/images/G/01/GPT/GAT/User_Guide.pdf`}
          target="_blank"
        >
          {bundle.formatMessage(GAT_STRINGS.GAT_USER_GUIDE)}{' '}
          <Icon tokens={exportSmallTokens} />
        </SideMenuLink>
      )}

      <div style={{ alignItems: 'end' }}>
        <Button
          type={'tertiary'}
          onClick={() => {
            signOut();
            navigate(PAGE_PATHS.WELCOME);
          }}
        >
          {bundle.formatMessage(GAT_STRINGS.GAT_SIGN_OUT_TEXT)}
        </Button>
      </div>
    </SideMenu>
  );
};
