import { TableSort } from 'src/interfaces/TableSort';

export const sortByColumn = (Requests: any, sortState: TableSort<any>) => {
  if (sortState.sortDirection && sortState.sortColumn) {
    Requests?.sort((a: any, b: any) => {
      const aValue = a?.[sortState.sortColumn] ?? null;
      const bValue = b?.[sortState.sortColumn] ?? null;
      if (aValue && bValue) {
        if (aValue < bValue) {
          return sortState.sortDirection === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortState.sortDirection === 'ascending' ? 1 : -1;
        }
      }
      return 0;
    });
  }
};
