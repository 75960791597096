import { css } from '@emotion/css';

export const DesktopCentralizeDiv = css`
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const MobileCentralizeDiv = css`
  top: 10%;
  left: 2.5%;
  right: 2.5%;
  position: fixed;
  align-items: center;
  justify-content: center;
`;

export const CentralizeLoader = css`
  position: fixed;
  align-self: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const sortMenuStyle = css`
  display: flex;
  flex-direction: column;
`;

export const sortStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const sortIconStyle = css`
  margin-left: 5px;
  &:hover {
    color: #004f6c;
  }
`;

export const ApproveAccessListHeader = css`
  overflow-x: auto;
  max-width: 100%;
`;
