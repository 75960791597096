import { PageLayout } from 'src/components';
import { useState, useEffect } from 'react';
import { APPROVE_ACCESS_PAGE_STRINGS } from '../constants';
import { TableActionBar } from '@amzn/meridian/table';
import Select, { SelectOption } from '@amzn/meridian/select';
import { Loader } from 'src/components';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import { ApproveAccessList } from './ApproveAccessList';
import { useProfileInfoStore } from 'src/store/profile-info';
import { useUserSites } from '../api';
import { useAuthorizationStore } from 'src/store/user-authorization-store';

export const ApproveAccessComponent = () => {
  const [siteSearchQuery, setSiteSearchQuery] = useState<string>('');
  const { bundle } = useProfileInfoStore();
  const [filterSite, setFilterSite] = useState('all');
  const [isAllSites, setIsAllSites] = useState(true);
  const [status, setStatus] = useState('PENDING');
  const { isSystemAdmin, isSiteAdmin } = useAuthorizationStore();
  const {
    data: siteList,
    isLoading: isSiteListLoading,
    error: siteListError,
  } = useUserSites(isSystemAdmin, isSiteAdmin);

  useEffect(() => {
    if (isAllSites) {
      setStatus('PENDING');
    }
  }, [isAllSites]);

  const handleSiteChange = (value: string) => {
    setFilterSite(value);
    setIsAllSites(value === 'all');
    if (value === 'all') {
      setStatus('PENDING');
    }
  };

  return (
    <PageLayout
      title={bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.APPROVE_ACCESS)}
      alignmentHorizontal="stretch"
    >
      {isSiteListLoading ? (
        <Loader />
      ) : siteListError ? (
        <Box spacingInset={'200'}>
          <Text type="b300">
            {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.SITE_FETCH_ERROR)}
          </Text>
        </Box>
      ) : (
        <>
          <Text>
            {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.INFO_MESSAGE)}
          </Text>
          <TableActionBar>
            <>
              <Select
                label={bundle.formatMessage(
                  APPROVE_ACCESS_PAGE_STRINGS.PICK_A_SITE,
                )}
                searchQuery={siteSearchQuery}
                onSearch={setSiteSearchQuery}
                width={200}
                value={filterSite}
                onChange={handleSiteChange}
              >
                {siteSearchQuery === '' && (
                  <SelectOption
                    key="all"
                    label={bundle.formatMessage(
                      APPROVE_ACCESS_PAGE_STRINGS.ALL_SITES,
                    )}
                    value="all"
                  />
                )}
                {siteList
                  ?.filter((site) =>
                    site.toLowerCase().includes(siteSearchQuery.toLowerCase()),
                  )
                  .map((site: string) => (
                    <SelectOption key={site} label={site} value={site} />
                  ))}
              </Select>
              <Select
                label={bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.STATUS)}
                width={200}
                value={status}
                onChange={setStatus}
              >
                <SelectOption
                  key={1}
                  label={bundle.formatMessage(
                    APPROVE_ACCESS_PAGE_STRINGS.PENDING_STATUS,
                  )}
                  value={'PENDING'}
                />
                {!isAllSites && (
                  <SelectOption
                    key={2}
                    label={bundle.formatMessage(
                      APPROVE_ACCESS_PAGE_STRINGS.APPROVED_STATUS,
                    )}
                    value={'APPROVED'}
                  />
                )}
              </Select>
            </>
          </TableActionBar>
          {filterSite !== '' && (
            <ApproveAccessList
              filterSite={isAllSites ? '' : filterSite}
              status={status}
              showSiteColumn={isAllSites}
            />
          )}
        </>
      )}
    </PageLayout>
  );
};
