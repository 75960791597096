import { useQuery } from '@tanstack/react-query';
import { ExtractFnReturnType } from 'src/lib/react-query';
import { OrchestratorApiService } from 'src/service';
import { GetRoleListResponse, GetRolesConfig } from 'src/features/access/types';
import { BACKEND_PATHS } from 'src/constants/backend-paths';

const getRoleList = async (countryCode?: string): Promise<GetRolesConfig[]> => {
  const response = await OrchestratorApiService.get(
    BACKEND_PATHS.ROLE_CONFIG(countryCode),
  );
  const data: GetRoleListResponse = response.data;
  return data.roles
    .map(({ roleName, roleId, metaData }) => ({
      role: roleName,
      roleId: roleId,
      metaData: JSON.parse(metaData),
    }))
    .sort((a: GetRolesConfig, b: GetRolesConfig) =>
      a.role.localeCompare(b.role),
    );
};

type QueryFnType = typeof getRoleList;

export const useRoleList = (countryCode?: string) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['getStaticRoleList'],
    queryFn: () => getRoleList(countryCode),
  });
};
