import {
  BundledLocalizationProps,
  LocalizationProps,
} from 'src/interfaces/BundleInterface';
import { TableSortDirection } from '@amzn/meridian/table/table';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import TableSortToggle from '@amzn/meridian/table-sort-toggle';
import { APPROVE_ACCESS_PAGE_STRINGS } from 'src/features/access/constants';
import { useAuthTokenPayload } from 'src/hooks';
import React, { useState } from 'react';
import ModifyAccessModal from 'src/features/access/components/ModifyAccessModal';
import { UserInfo } from 'src/features/home/types';
import Column from '@amzn/meridian/column';
import Pagination from '@amzn/meridian/pagination';
import { TableSort } from 'src/interfaces/TableSort';
import { useProfileInfoStore } from 'src/store/profile-info';
import { AccessAction } from 'src/features/access/types/access-action';
import { ApproveAccessActionButtons } from 'src/features/access/components/ApproveAccessActionButtons';
import { sortByColumn } from 'src/features/access/utils/sort-by-column-util';
import { ApproveAccessListHeader } from 'src/styles';

const itemsPerPage = 10;

const ApprovalRequestsTableHeader = ({
  bundle,
  sort,
  onSort,
  showSiteColumn,
}: {
  bundle: LocalizationProps;
  sort: { sortColumn: string; sortDirection: TableSortDirection };
  onSort: any;
  showSiteColumn: boolean;
}) => (
  <TableRow>
    <TableCell width={showSiteColumn ? '30%' : '40%'}>
      <TableSortToggle sortColumn="userId" onChange={onSort} value={sort}>
        {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.USER)}
      </TableSortToggle>
    </TableCell>
    {showSiteColumn && (
      <>
        <TableCell sortColumn="siteId" width={'20%'}>
          <TableSortToggle sortColumn="siteId" onChange={onSort} value={sort}>
            {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.SITE)}
          </TableSortToggle>
        </TableCell>
        <TableCell sortColumn="siteCountryCode" width={'20%'}>
          <TableSortToggle
            sortColumn="siteCountryCode"
            onChange={onSort}
            value={sort}
          >
            {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.COUNTRY)}
          </TableSortToggle>
        </TableCell>
      </>
    )}
    <TableCell sortColumn="role" width={'20%'}>
      <TableSortToggle sortColumn="role" onChange={onSort} value={sort}>
        {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.ROLE)}
      </TableSortToggle>
    </TableCell>
    <TableCell width={'20%'}>
      {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.ACTION)}
    </TableCell>
  </TableRow>
);

const ApprovalRequestsTableRow: React.FC<
  {
    approvalInfo: UserInfo;
    refetch: any;
    showSiteColumn: boolean;
  } & BundledLocalizationProps
> = ({ approvalInfo, refetch, bundle, showSiteColumn }) => {
  const { role, recordId, siteId, siteCountryCode, status, userId } =
    approvalInfo;
  const { authTokenPayload } = useAuthTokenPayload();
  const [isModifyAccessModalOpen, setIsModifyAccessModalOpen] = useState(false);
  const [actionType, setActionType] = useState<AccessAction>('APPROVED');

  return !authTokenPayload.loading ? (
    <>
      <TableRow>
        <TableCell>{userId}</TableCell>
        {showSiteColumn && <TableCell>{siteId}</TableCell>}
        {showSiteColumn && <TableCell>{siteCountryCode}</TableCell>}
        <TableCell>{role}</TableCell>
        <TableCell>
          <ApproveAccessActionButtons
            status={status}
            setActionType={setActionType}
            setIsModifyAccessModalOpen={setIsModifyAccessModalOpen}
            bundle={bundle}
          />
        </TableCell>
      </TableRow>
      {isModifyAccessModalOpen && (
        <ModifyAccessModal
          isOpen={isModifyAccessModalOpen}
          onClose={() => setIsModifyAccessModalOpen(false)}
          role={role}
          siteId={siteId}
          recordId={recordId}
          consensus={actionType}
          email={userId}
          refetchUserRequestedRoles={refetch}
          bundle={bundle}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export const ApproveAccessListTableView: React.FC<{
  approvalRequests: UserInfo[];
  refetch: any;
  showSiteColumn: boolean;
}> = ({ approvalRequests, refetch, showSiteColumn }) => {
  const [sortState, setSortState] = useState<TableSort<keyof UserInfo>>({
    sortColumn: 'userId',
    sortDirection: 'ascending',
  });

  const numberOfPages = Math.ceil(
    (approvalRequests?.length ?? 0) / itemsPerPage,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  const { bundle } = useProfileInfoStore();
  const onSort = ({
    sortColumn,
    sortDirection,
  }: {
    sortColumn: keyof UserInfo;
    sortDirection: TableSortDirection;
  }) => {
    setSortState({ sortColumn, sortDirection });
  };

  sortByColumn(approvalRequests, sortState);

  return (
    <>
      <div className={ApproveAccessListHeader}>
        <Table
          rowComponents={[
            ApprovalRequestsTableHeader,
            ApprovalRequestsTableRow,
          ]}
          showDividers={true}
          showStripes={true}
          headerRows={1}
          headerColumns={1}
        >
          {approvalRequests?.length && (
            <ApprovalRequestsTableHeader
              sort={{
                sortColumn: sortState.sortColumn,
                sortDirection: sortState.sortDirection,
              }}
              onSort={onSort}
              bundle={bundle}
              showSiteColumn={showSiteColumn}
            />
          )}
          {approvalRequests
            ?.slice(firstVisibleIndex, lastVisibleIndex)
            .map((approvalInfo: UserInfo) => (
              <ApprovalRequestsTableRow
                key={`${approvalInfo.userId}-${approvalInfo.siteId}-${approvalInfo.role}-${approvalInfo.roleId}`}
                approvalInfo={approvalInfo}
                refetch={refetch}
                bundle={bundle}
                showSiteColumn={showSiteColumn}
              />
            ))}
        </Table>
      </div>
      <Column alignmentHorizontal="end">
        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Column>
    </>
  );
};
