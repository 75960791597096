import { useApprovalRequests } from '../api/get-approval-requests';
import { Loader } from 'src/components';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import { APPROVE_ACCESS_PAGE_STRINGS } from '../constants';
import { useProfileInfoStore } from 'src/store/profile-info';
import { ApproveAccessListCardView } from 'src/features/access/components/ApproveAccessListCardView';
import { ApproveAccessListTableView } from 'src/features/access/components/ApproveAccessListTableView';
import { useIsMobile } from 'src/hooks';

export const ApproveAccessList: React.FC<{
  filterSite: string;
  status: string;
  showSiteColumn: boolean;
}> = ({ filterSite, status, showSiteColumn }) => {
  const {
    data: approvalRequests,
    isLoading: isApprovalRequestsLoading,
    error: approvalRequestsError,
    refetch,
  } = useApprovalRequests(filterSite as string, status);

  const { bundle } = useProfileInfoStore();
  const isMobile = useIsMobile();

  return (
    <>
      {isApprovalRequestsLoading ? (
        <Loader />
      ) : approvalRequestsError ? (
        <Box spacingInset={'200'}>
          <Text type="b300">
            {bundle.formatMessage(
              APPROVE_ACCESS_PAGE_STRINGS.APPROVAL_REQUESTS_ERROR,
            )}
          </Text>
        </Box>
      ) : approvalRequests?.userRoles.length === 0 ? (
        <Box spacingInset={'200'}>
          <Text type="b300">
            {bundle.formatMessage(
              APPROVE_ACCESS_PAGE_STRINGS.NO_REQUESTS_FOUND,
            )}
          </Text>
        </Box>
      ) : (
        <>
          {isMobile ? (
            <ApproveAccessListCardView
              approvalRequests={approvalRequests?.userRoles ?? []}
              refetch={refetch}
              showSiteColumn={showSiteColumn}
            />
          ) : (
            <ApproveAccessListTableView
              approvalRequests={approvalRequests?.userRoles ?? []}
              refetch={refetch}
              showSiteColumn={showSiteColumn}
            />
          )}
        </>
      )}
    </>
  );
};
