import { useState } from 'react';

export type ThemeMode = 'light' | 'dark';

const gatSystemTheme = 'gat-theme-mode';
const useThemeMode = () => {
  const getInitialTheme = (): ThemeMode => {
    if (typeof window === 'undefined') return 'light';

    const storedTheme = window.localStorage.getItem(gatSystemTheme);
    if (storedTheme && (storedTheme === 'light' || storedTheme === 'dark')) {
      return storedTheme as ThemeMode;
    }
    return 'light';
  };

  const [themeMode, setThemeMode] = useState<ThemeMode>(getInitialTheme);

  const setAndStoreThemeMode = (newTheme: ThemeMode) => {
    setThemeMode(newTheme);
    localStorage.setItem(gatSystemTheme, newTheme);
  };

  return {
    themeMode,
    setThemeMode: setAndStoreThemeMode,
  };
};

export default useThemeMode;
